const Rules = {
  namePattern: (value) => {
    const pattern = /[A-Za-z]/
    return pattern.test(value) || 'Invalid format.'
  },
  emailPattern: (value) => {
    const pattern = /((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])|none|n\/a)/
    return (
      !value || pattern.test(value) || 'Invalid format. e.g. email@address.com'
    )
  },
  yearPattern: (value) => {
    const pattern = /^\d{4}$/
    return !value || pattern.test(value) || 'Invalid Year.'
  },
  novcNumberPattern: (value) => {
    const pattern = /^[\d-\s]+$/
    return !value || pattern.test(value) || 'Invalid Number.'
  },
  numberPattern: (value) => {
    const pattern = /^\d{3}-\d{3}-\d{4}$/
    return !value || pattern.test(value) || 'Invalid Phone Number.'
  },
  zipPattern: (value) => {
    const pattern = /^\d{5}$/
    return pattern.test(value) || 'Invalid zip format.'
  },
  hoursLessThan24: (v) => {
    if (v && v.includes(':')) {
      let time = v.split(':')
      let h = time[0].replace(/[^0-9.]/g, '')
      let hours = 0
      if (h.length > 0) {
        hours = parseInt(h, 10)
      }
      let m = time[1].replace(/[^0-9]/g, '')
      let minutes = 0
      //if they enter hours:minutes like 1:3 we are going to assume they mean 1:30 not 1:03
      if (m.length > 1) {
        minutes = parseInt(m, 10)
      } else if (m.length > 0) {
        minutes = parseInt(m.toString() + '0', 10)
      }
      //make sure that it was able to parse and both are not 0
      if (hours < 24 || (hours == 24 && minutes == 0)) {
        return true
      } else {
        // we have an invalid or 0 time
        return false
      }
    } else {
      let newTime = parseFloat(v)
      if (newTime <= 24) {
        return true
      } else {
        return false
      }
    }
  },
  hoursGreaterThanZero: (v) => {
    if (v && v.includes(':')) {
      let time = v.split(':')
      let h = time[0].replace(/[^0-9.]/g, '')
      let hours = 0
      if (h.length > 0) {
        hours = parseInt(h, 10)
      }
      let m = time[1].replace(/[^0-9]/g, '')
      let minutes = 0
      //if they enter hours:minutes like 1:3 we are going to assume they mean 1:30 not 1:03
      if (m.length > 1) {
        minutes = parseInt(m, 10)
      } else if (m.length > 0) {
        minutes = parseInt(m.toString() + '0', 10)
      }
      //make sure that it was able to parse and both are not 0
      if (hours > 0 || minutes > 0) {
        return true
      } else {
        // we have an invalid or 0 time
        return 'hours must be greater than 0'
      }
    } else {
      let newTime = parseFloat(v)
      if (newTime > 0) {
        return true
      } else {
        return 'hours must be greater than 0'
      }
    }
  },
  hoursValidTime: (v) => {
    if (v) {
      if (v.includes(':')) {
        let time = v.split(':')
        let h = time[0].replace(/[^0-9.]/g, '')
        let hours = 0
        if (h.length > 0) {
          hours = parseInt(h, 10)
        }
        let m = time[1].replace(/[^0-9]/g, '')
        let minutes = 0
        //if they enter hours:minutes like 1:3 we are going to assume they mean 1:30 not 1:03
        if (m.length > 1) {
          minutes = parseInt(m, 10)
        } else if (m.length > 0) {
          minutes = parseInt(m.toString() + '0', 10)
        }
        //make sure that it was able to parse
        if (
          hours >= 0 &&
          (minutes == 0 || minutes == 15 || minutes == 30 || minutes == 45)
        ) {
          return true
        } else {
          // we have an invalid time.  changing it to -1 and back on next tick resets it to the previous value
          return 'Minutes must be in 15 min increments'
        }
      } else {
        let newTime = parseFloat(v.replace(/[^0-9.]/g, ''))
        let partial = (newTime % 1).toFixed(2)
        if (
          newTime &&
          newTime >= 0 &&
          (partial == 0.0 ||
            partial == 0.25 ||
            partial == 0.5 ||
            partial == 0.75)
        ) {
          return true
        } else {
          // we have an invalid number.  changing it to -1 and back on next tick resets it to the previous value
          return 'Time must be in .25 increments'
        }
      }
    } else {
      return 'Hours is required'
    }
  },
  amountSeparatorPattern: (value) => {
    const pattern = /^-?[\d,]{0,12}(\.[\d]{0,2})?$/
    return pattern.test(value) || 'Invalid amount.'
  },
  amountPattern: (value) => {
    const pattern = /^-?[\d]{0,10}(\.[\d]{0,2})?$/
    return pattern.test(value) || 'Invalid amount.'
  },
  numberPattern: (value) => {
    const pattern = /[0-9]/
    return pattern.test(value) || 'Invalid entry.'
  },
  fieldRequired: (value) => !!value || 'Required.',
}
export default Rules
