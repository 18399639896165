<template>
  <v-row>
    <v-col sm="12">
      <v-card>
        <v-expansion-panels v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header
              class="primary headline text-left white--text"
            >
              Contacts
              <template v-slot:actions>
                <v-icon class="white--text">$vuetify.icons.expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row no-gutters justify="end">
                  <v-col sm="auto" class="mt-4">
                    <v-tooltip bottom>
                      <template v-slot:activator="{on}">
                        <v-btn
                          color="primary"
                          v-on="on"
                          @click="newContact"
                          fab
                          depressed
                          small
                        >
                          <v-icon dark>mdi-account-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>Add Contact</span>
                    </v-tooltip>
                    <v-dialog
                      persistent
                      v-model="dialog"
                      @keydown.esc="dialog = false"
                      width="850"
                    >
                      <v-card class="elevation-3">
                        <v-card-title class="primary">
                          <h3 class="hpHeading white--text">{{ modalText }}</h3>
                        </v-card-title>
                        <v-card-text class="pa-4">
                          <v-form ref="form" lazy-validation>
                            <v-row>
                              <v-col class="py-0" sm="12" md="4">
                                <v-text-field
                                  v-model="selectedContact.Contact.OfficeTitle"
                                  label="Title"
                                ></v-text-field>
                              </v-col>
                              <v-col class="py-0" sm="12" md="4">
                                <v-text-field
                                  v-model="selectedContact.Contact.FirstName"
                                  label="First Name"
                                ></v-text-field>
                              </v-col>
                              <v-col class="py-0" sm="12" md="4">
                                <v-text-field
                                  v-model="selectedContact.Contact.LastName"
                                  label="Last Name"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col class="py-0" sm="12" md="4">
                                <v-text-field
                                  v-model="selectedContact.Contact.Phone"
                                  label="Phone"
                                  v-mask="maskPhone"
                                ></v-text-field>
                              </v-col>
                              <v-col class="py-0" sm="12" md="1">
                                <v-text-field
                                  v-model="selectedContact.Contact.Extension"
                                  label="Ext."
                                  v-mask="maskExt"
                                ></v-text-field>
                              </v-col>
                              <v-col class="py-0" sm="12" md="3">
                                <v-text-field
                                  v-model="selectedContact.Contact.Fax"
                                  label="Fax"
                                  v-mask="maskPhone"
                                ></v-text-field>
                              </v-col>
                              <v-col class="py-0" sm="12" md="4">
                                <v-text-field
                                  v-model="selectedContact.Contact.Email"
                                  label="Email"
                                  :rules="[rules.emailPattern]"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col class="py-0" sm="12">
                                <v-text-field
                                  v-model="
                                    selectedContact.Contact.Address.Address1
                                  "
                                  label="Address"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col class="py-0" sm="12" md="4">
                                <v-text-field
                                  v-model="selectedContact.Contact.Address.City"
                                  label="City"
                                ></v-text-field>
                              </v-col>
                              <v-col class="py-0" sm="12" md="4">
                                <v-select
                                  v-model="
                                    selectedContact.Contact.Address.StateId
                                  "
                                  :items="luState"
                                  item-text="Abbreviation"
                                  item-value="ID"
                                  label="State"
                                  menu-props="auto, overflowY"
                                  clearable
                                ></v-select>
                              </v-col>
                              <v-col class="py-0" sm="12" md="4">
                                <v-text-field
                                  v-model="selectedContact.Contact.Address.Zip"
                                  label="Zip"
                                ></v-text-field>
                                <!-- v-mask="maskZip" -->
                              </v-col>
                            </v-row>
                            <v-card-actions>
                              <v-btn
                                color="primary"
                                depressed
                                @click="validateAndSaveContact"
                                :disabled="saving"
                                >Accept</v-btn
                              >
                              <v-btn
                                outlined
                                color="primary"
                                @click="dialog = !dialog"
                                >Cancel</v-btn
                              >
                            </v-card-actions>
                          </v-form>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
              </v-card-text>
              <template>
                <v-data-table
                  :headers="headers"
                  :items="entityContacts"
                  :items-per-page="5"
                  class="pt-4"
                  :search="search"
                  dense
                >
                  <template v-slot:item.view="{item}">
                    <v-tooltip bottom>
                      <template v-slot:activator="{on}">
                        <v-btn
                          small
                          color="primary"
                          v-on="on"
                          icon
                          @click="editContact(item)"
                        >
                          <v-icon class="clickable" color="primary"
                            >mdi-pencil</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Edit Contact</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.address="{item}">
                    <span
                      >{{ item.Contact.Address.Address1 }},
                      {{ item.Contact.Address.City }},
                      {{ item.Contact.Address.StateId | getState(luState) }}
                      {{ item.Contact.Address.Zip }}</span
                    >
                  </template>
                  <template v-slot:item.delete="{item}">
                    <v-tooltip bottom>
                      <template v-slot:activator="{on}">
                        <v-btn small color="primary" v-on="on" icon>
                          <v-icon
                            class="clickable"
                            color="primary"
                            @click="deleteConfirm(item)"
                            >mdi-trash-can</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Delete Contact</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-dialog
                  persistent
                  v-model="deleteDialog"
                  scrollable
                  width="350"
                >
                  <v-card class="elevation-3">
                    <v-card-title class="primary">
                      <h3 class="hpHeading white--text">Confirm?</h3>
                    </v-card-title>
                    <v-card-text class="pa-4">
                      <v-row no-gutters>
                        <v-col sm="12"
                          >Are you sure you want to delete this contact?</v-col
                        >
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        color="primary"
                        @click="deleteDialog = !deleteDialog"
                        class="white--text"
                        >Cancel</v-btn
                      >
                      <v-btn @click="callDelete" outlined color="primary"
                        >Delete</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
  </v-row>
</template>
<!-- SCRIPTS -->
<script>
import {mask} from 'vue-the-mask'
import {get, sync, commit, call} from 'vuex-pathify'
import Contact from '@classes/Contact'
import EntityContact from '@classes/EntityContact'
import SubEntityContact from '@classes/SubEntityContact'
import Rules from '@validation/rules'
export default {
  directives: {
    mask,
  },
  // model: {
  //   // prop: 'entityContacts',
  //   event: 'change',
  // },
  props: {
    entityContacts: Array,
    subEntityId: Number,
    entityId: Number,
  },
  data: () => ({
    rules: Rules,
    maskPhone: '###-###-####',
    maskExt: '#####',
    maskZip: '#####',
    panel: 0,
    search: '',
    valid: true,
    headers: [
      {
        text: '',
        align: 'center',
        value: 'view',
        sortable: false,
      },
      {
        text: 'First Name',
        align: 'center',
        sortable: 'true',
        value: 'Contact.FirstName',
      },
      {
        text: 'Last Name',
        align: 'center',
        sortable: 'true',
        value: 'Contact.LastName',
      },
      {
        text: 'Title',
        align: 'center',
        sortable: 'true',
        value: 'Contact.OfficeTitle',
      },
      {
        text: 'Phone',
        align: 'center',
        sortable: 'true',
        value: 'Contact.Phone',
      },
      {
        text: 'Email',
        align: 'center',
        sortable: 'true',
        value: 'Contact.Email',
      },
      {
        text: 'Address',
        align: 'center',
        sortable: 'true',
        value: 'address',
      },
      {
        text: '',
        align: 'center',
        value: 'delete',
      },
    ],
    dialog: false,
    deleteDialog: false,
    modalText: '',
    UserRoles: '',
    selectedContact: {Contact: new Contact()},
    saving: false,
  }),
  filters: {
    getState(id, states) {
      if (!id || id < 1 || !id) {
        return ''
      }
      let match = states.find((item) => {
        return item.ID === id
      })
      if (match) {
        return match.Abbreviation
      } else {
        return ''
      }
    },
  },
  created() {
    this.loadLuState()
  },
  computed: {
    luState: get('luState/luState'),
  },
  methods: {
    loadLuState: call('luState/loadLuState'),
    saveEntityContact: call('entityContact/saveEntityContact'),
    saveSubEntityContact: call('subEntityContact/saveSubEntityContact'),
    deleteEntityContact: call('entityContact/deleteEntityContact'),
    deleteSubEntityContact: call('subEntityContact/deleteSubEntityContact'),
    newContact() {
      this.selectedContact = {ID: 0, ContactId: 0, Contact: new Contact()}
      this.modalText = 'Create Contact'
      this.dialog = !this.dialog
    },
    editContact(entry) {
      this.modalText = 'Edit Contact'
      //Object.assign can not be used to break the connection to the parent object with deep objects
      this.selectedContact = JSON.parse(JSON.stringify(entry))
      this.dialog = !this.dialog
    },
    deleteConfirm(item) {
      this.selectedContact = JSON.parse(JSON.stringify(item))
      this.deleteDialog = true
    },
    callDelete() {
      if (this.selectedContact.EntityId) {
        this.deleteEntityContact(this.selectedContact).then((res) => {
          this.$emit('refresh')
          this.deleteDialog = false
        })
      } else if (this.selectedContact.SubEntityId) {
        this.deleteSubEntityContact(this.selectedContact).then((res) => {
          this.$emit('refresh')
          this.deleteDialog = false
        })
      }
    },
    validateAndSaveContact() {
      this.validate().then(() => {
        if (this.valid) {
          if (!this.selectedContact.ID) {
            if (this.entityId) {
              this.selectedContact.EntityId = this.entityId
            } else if (this.subEntityId) {
              this.selectedContact.SubEntityId = this.subEntityId
            }
          }
          if (this.selectedContact.EntityId) {
            this.saving = true
            this.saveEntityContact(
              new EntityContact(this.selectedContact).getSaveData()
            ).then(
              (res) => {
                this.saving = false
                this.$emit('refresh')
                this.dialog = false
              },
              (err) => {
                this.saving = false
              }
            )
          } else if (this.selectedContact.SubEntityId) {
            this.saving = true
            this.saveSubEntityContact(
              new SubEntityContact(this.selectedContact).getSaveData()
            ).then(
              (res) => {
                this.saving = false
                this.$emit('refresh')
                this.dialog = false
              },
              (err) => {
                this.saving = false
              }
            )
          }
        }
      })
    },
    async validate() {
      this.$refs.form.validate()
    },
  },
}
</script>

<!-- STYLES -->
<style lang="scss">
//@import '@design';
.v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
